import React from 'react'

class LoginLayout extends React.Component<any, any> {
  render() {
    return (
      <>
        {this.props.children}
      </>
    )
  }
}

export default LoginLayout
